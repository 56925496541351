<template>
  <card :title="$t('Настройка доступа')">
    <load-block :load="load"></load-block>
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <label>{{ $t("Роль") }}</label>
          <div class="form-group has-label">
            <div class="el-input">
              <v-select label="entityName" :options="option" @input="setRole" :value="role">
                <span slot="no-options">{{ $t("Результатов не найдено") }}</span>
              </v-select>
            </div>
          </div>
        </div>
        <div class="col-2">
          <label>&nbsp;</label>
          <l-button
            type="success"
            wide
            block
            @click="saveData"
            :disabled="!role"
            v-if="hasSave"
          >{{ $t("Сохранить") }}</l-button>
        </div>
      </div>
      <TableControl
        :load="load"
        :getData="loadDataRole"
        v-if="hasRead && role && tableData && tableData.length > 0"
        :hasChange="setChange"
      />
      <div v-if="!hasRead">У вас нет доступа к просмотру</div>
    </div>
  </card>
</template>

<script>
import { API_METHODS, TYPE_ERRORS } from "../../../constant";
import { api } from "../../../util/api";
import TableControl from "./TableControl";
import { LOAD_ROLE_USER, UPDATE_ROLE_USER } from "./store/type";
import LoadBlock from "../../shared/LoadBlock";
import swal from "sweetalert2";
import notify from "../../../helpers/notify/notify";
import { NAME_ACTION, NAME_OBJECT } from "../../../RoleConstanans";
export default {
  name: "AccessControls",
  components: {
    TableControl,
    LoadBlock
  },
  data() {
    return {
      tabPosition: "top",
      show: false,
      nowTab: "entitis",
      option: [],
      role: null,
      load: false,
      hasChange: false
    };
  },
  computed: {
    userRoleLouded() {
      return this.$store.getters.userRoleLouded;
    },
    hasRead() {
      return (
        this.userRoleLouded &&
        this.isActionAccess(NAME_OBJECT.Admin, NAME_ACTION.isRead)
      );
    },
    hasSave() {
      return (
        this.userRoleLouded &&
        this.isActionAccess(NAME_OBJECT.Admin, NAME_ACTION.isEdit)
      );
    },
    tableData() {
      return this.$store.state.setting.dataRole;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    isActionAccess(name, actions) {
      return this.$store.getters.isActionAccess(name, actions);
    },
    async saveData() {
      try {
        let params = {
          self: this,
          RoleId: this.role.entityId,
          saveData: this.tableData
        };
        this.load = true;
        await this.$store.dispatch(UPDATE_ROLE_USER, params);
        this.hasChange = false;
        notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
        this.load = false;
      } catch (e) {
        console.error(e);
        notify(this, this.$t("Ошибка"), TYPE_ERRORS.DANGER);
        this.load = false;
      }
    },
    async loadData() {
      try {
        const params = {
          self: this,
          relative: "Role",
          query: {
            lang: this.$store.state.common.lang
          }
        };
        this.load = true;
        const res = await api(API_METHODS.BASE_V_SELECT_LOAD_DATA, params);
        let responseData = res.object;
        if (!("map" in responseData)) {
          responseData = [responseData];
        }
        this.option = responseData;
        this.load = false;
        return res;
      } catch (err) {
        return err;
      }
    },
    async loadDataRole(updateData=true) {
      try {
        let params = {
          self: this,
          updateData,
          RoleId: this.role.entityId
        };
        this.load = true;
        await this.$store.dispatch(LOAD_ROLE_USER, params);
        this.load = false;
      } catch (e) {
        this.load = false;
        console.error(e);
      }
    },
    setChange() {
      this.hasChange = true;
    },
    setRole(val) {
      if (this.hasChange) {
        this.$nextTick(() => {
          swal({
            title: this.$t("Вы уверены?"),
            text: this.$t("roleInfo"),
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            confirmButtonText: this.$t("Да"),
            cancelButtonText: this.$t("Закрыть"),
            buttonsStyling: false
          })
            .then((res) => {
              if(res && res.value && res.value == true) {
                 this.role = val;
              }
            })
        });
      } else {
        this.role = val;
      }
    }
  },
  watch: {
    role() {
      this.hasChange = false
      this.$store.commit(LOAD_ROLE_USER, { data: [], fieldInfoList: [] });
      this.loadDataRole();
    }
  }
};
</script>

<style lang="scss" scoped>
.control {
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  margin: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
</style>
