<template>
  <the-table-page
    :tableData="tableData"
    :load="load"
    fieldId="id"
    :updateData="updateData"
    :fieldInfoList="fieldInfoList"
    :searchStart="searchStart"
    :sortData="sortData"
    :setSort="setSort"
    :deleteEl="deleteEl"
    configName="userRole"
    :forceIsCreate="false"
    :forceDelete="false"
    :forsePaginate="false"
    :forsePerpage="false"
    :noTitle="true"
    :hasAction="false"
    :customTemplate="true"
    :setChange="setChange"
    :sortable="false"
    :forseSearch="false"
    :roleName="nameRoleEntity"
  >
  </the-table-page>
</template>
<script>
import TheTablePage from "../../TheTablePage.vue";
import { LOAD_ROLE_USER, CHANGE_DATA_ROLE } from "./store/type";

import {
  SET_MAIN_PAGINATION,
  SET_MAIN_PAGINATION_PER_PAGE,
  SET_MAIN_PAGINATION_PAGE,
  SET_MAIN_SORT_ENTITY,
  SET_MAIN_SEARCH_ENTITY
} from "../../../../src/store/type";

import { READ, DELETE } from "../../../store/type";
import { mapState } from "vuex";
import NotificationSample from "src/pages/Dashboard/Components/NotificationSample";
import {
  DEFAULT_PER_PAGE,
  TYPE_ERRORS,
  GET_MEMORY_PER_PAGE
} from "../../../constant";
import notify from "../../../helpers/notify/notify";
import convertLangDataToTable from "../../../helpers/converters/convertLangDataToTable";
import { NAME_OBJECT } from "../../../RoleConstanans";
export default {
  components: {
    TheTablePage
  },
  props: {
    getData: { type: Function },
    hasChange: { type: Function },
    load: {}
  },
  data() {
    return {
      // load: false
    };
  },
  computed: {
    ...mapState({
      fieldInfoList: state => state.setting.fieldInfoListRole,
      lang: state => state.common.lang,
      sortData: state => state.pagination.sortData
    }),
    nameRoleEntity() {
      return NAME_OBJECT.Admin;
    },
    tableData() {
      let langNow = this.$store.state.common.lang;
      let dataNow = this.$store.state.setting.dataRole || [];
      if (dataNow && dataNow.length > 0) {
        dataNow = convertLangDataToTable(dataNow, langNow, "", false);

        dataNow = dataNow.filter(item => {
          const showCode = [
            "EventLog",
            "Reference",
            "ReferenceSystem",
            "Esb",
            "Admin",
            "CrmHolding",
            "CrmCorp",
            "Cfo",
            "ReceiverSystems"
          ];
          const find = showCode.find(it => it == item.elementCode);
          if (find) {
            return true;
          }
          return false;
        });

        return dataNow;
      }
    }
  },
  watch: {
    lang() {
      this.getData(false);
    }
  },
  methods: {
    updateData() {
      this.getData();
    },
    searchStart(search) {
      this.$store.commit(SET_MAIN_SEARCH_ENTITY, search);
      this.getData();
    },
    setSort(val) {
      this.$store.commit(SET_MAIN_SORT_ENTITY, val);
      this.getData();
    },
    deleteEl(id) {},
    initData() {
      this.$store.commit(SET_MAIN_SORT_ENTITY, {});
      this.$store.commit(SET_MAIN_SEARCH_ENTITY, "");
      this.$store.commit(SET_MAIN_PAGINATION_PER_PAGE, GET_MEMORY_PER_PAGE());
      this.$store.commit(SET_MAIN_PAGINATION_PAGE, 1);
    },
    setChange(row, field) {
      let elementCode = row.elementCode;
      let params = field;
      this.$store.commit(CHANGE_DATA_ROLE, { elementCode, params });
      this.hasChange();
    }
  }
};
</script>
